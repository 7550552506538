<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/art">My art</router-link> |
    <router-link to="/form">Mint NFT</router-link>
    <Signin/>
    
  </div>
  <router-view/>
</template>
<script>
import Signin from '@/components/Signin.vue'
export default {
  components:{
    Signin,
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
